import React from 'react';
import { connect } from 'react-redux';

import { socket } from 'api';
import { closeWindow } from 'store/actions/windows';
import { WindowWrapper } from './window-wrapper';
import { Close, Content, Header, Tab, TabsBox } from './window.styled';

class Window extends React.Component {
  state = {
    selectedTab: '',
  };

  componentDidMount() {
    this._mounted = true;
    if (this.props.tabs) {
      this.setState({ selectedTab: this.props.acctiveTab ? this.props.acctiveTab : this.props.tabs[0].id });
    }

    if (this.props.withMode) {
      this.timer = setInterval(() => {
        socket.call('get_state_background', { target: this.props.id, user_id: window.user.getId() });
        //console.log('🚀 ~ this.timer=setInterval ~ this.props.id', this.props.id);
        //this.props.sendControlMsg('get_state', undefined, {}, false);
      }, 3000);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  getTabs = () => {
    let tabsArr = [];
    if (this.props.tabs) {
      tabsArr = this.props.tabs.map((item) => {
        return (
          <Tab key={'tb' + item.id} active={this.state.selectedTab === item.id} onClick={() => this.switchTab(item.id)}>
            {item.text}
          </Tab>
        );
      });
    }

    return tabsArr;
  };

  getContent = () => {
    // children
    if (this.props.children) {
      // console.log('-> children');
      return this.props.children;
    }

    // tab
    if (this.props.tabs) {
      // console.log('-> tabs');
      if (this.state.selectedTab) {
        // console.log('-> tab');
        const Component = this.props.content[this.state.selectedTab];
        return <Component {...this.props} />;
      }
      return null;
    }

    // return
    // console.log('-> content');
    const Component = this.props.content;
    return <Component {...this.props} />;

    //console.log(Component, typeof Component, this.props )
    // return Component ? typeof Component === 'object' ? Component : <Component {...this.props} /> : this.props.children;
  };

  switchTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  render() {
    const { defaultPosition } = this.props;
    const content = this.getContent();
    // console.log('🚀 ~ render ~ content:', content);
    //height={this.props.height}
    return (
      <WindowWrapper width={this.props.width} handle=".header" defaultPosition={defaultPosition}>
        <>
          <Header className="header">
            <p>{this.props.header}</p>
            <Close href="#" onClick={() => this.props.closeWindow(this.props.id)}>
              <img src="./images/close_icon.png" alt="X" />
            </Close>
          </Header>
          {this.props.tabs ? <TabsBox>{this.getTabs()}</TabsBox> : null}
          <Content contentHeight={this.props.contentHeight}>{content}</Content>
        </>
      </WindowWrapper>
    );
  }
}

export default connect(null, {
  closeWindow,
})(Window);
